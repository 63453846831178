/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* resulting css */
/* resulting css */

@font-face {
  font-family: 'SV Basic Manual';
  src: url('./components/fonts/SvBasicManual.woff') format('woff');
  
  /* Add more src formats for better browser compatibility */
}
.app/* resulting css */
{
  filter: contrast(250%) brightness(200%);
  background: 
	linear-gradient(300deg, rgba(196, 128, 10, 0.36), rgba(0,0,0,0.9)), 
  radial-gradient(at var(--mouse-x) var(--mouse-y), rgba(255,255,255,0.5),  rgba(0,0,0,0.5)),
	url(./noise.svg);
  height: 100vh;
}
  


.footer {
  display: flex; /* Use a flex container to align the divs horizontally */
  justify-content: center; /* Center horizontally */
  align-items: center;

}

.footer div, a {
  font-family: 'SV Basic Manual';
  color: white;
  text-align: center;
  margin-right: 10px; /* Add some spacing between the divs */
  margin-left: 10px; /* Add some spacing between the divs */
}