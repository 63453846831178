@font-face {
  font-family: 'SV Basic Manual';
  src: url('../fonts/SvBasicManual.woff') format('woff');
  
  /* Add more src formats for better browser compatibility */
}
@font-face {
  font-family: 'SV Basic Manual Bold';
  src: url('../fonts/SvBasicManual-Bold.woff') format('woff');
  
  /* Add more src formats for better browser compatibility */
}
@font-face {
  font-family: 'Powerr-Bold';
  src: url('../fonts/Powerr-Bold.woff') format('woff');
  
  /* Add more src formats for better browser compatibility */
}

:root {
  --border-size: 0.125rem;
  --duration: 250ms;
  --ease: cubic-bezier(0.215, 0.61, 0.355, 1);
  /* --font-family: monospace; */
  --color-primary: white;
  --color-secondary: rgba(204, 143, 15, 0.7);
  /* --color-tertiary: #7557F1; */
  --shadow: rgba(0, 0, 0, 0.1);
  --space: 1rem;
}

  
.section-home span{
  color: white;
}
.section-home h1{
  color: white;
  font-size: 5em;
  
}
.section-home span:hover{
  color: var(--color-secondary);
}
.section-home {
  font-family: 'Powerr Bold';
  text-align: center;
  
  padding: 20px;
  
}

.btn-div-home {
  margin: 0 auto;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
  width: 50%;
 
}

.btn-div-home a{
  font-family: 'SV Basic Manual Bold';
  border-style: none solid solid none;
  border-color: white;
  color: var(--color-secondary);
  background-color: black;
  padding: 10px 20px 10px 20px;
  margin: 10px 10px 10px 10px;
  white-space: nowrap;
  box-shadow: var(--shadow) -2px -2px 4px 4px;
}


.btn-div-home a:hover {
  color: black;
  background-color: white;
  
}


/* Typewriter */
.section-home p{
  font-family: 'SV Basic Manual';
  border-right: solid 3px rgba(0,255,0,.75);
  white-space: nowrap;
  overflow: hidden;    
  font-size: 28px;
  color: rgba(255,255,255,.70);
  margin: 0 auto;
}

/* Animation */
.section-home p{
  animation: animated-text 3s steps(29,end) 1s 1 normal both,
             animated-cursor 600ms steps(29,end) infinite;
}

/* text animation */

@keyframes animated-text{
  from{width: 0;}
  to{width: 230px;}
}

/* cursor animations */

@keyframes animated-cursor{
  from{border-right-color: var(--color-secondary);}
  to{border-right-color: transparent;}
}


