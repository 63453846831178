body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* resulting css */
/* resulting css */

@font-face {
  font-family: 'SV Basic Manual';
  src: url(/static/media/SvBasicManual.94f4609a.woff) format('woff');
  
  /* Add more src formats for better browser compatibility */
}
.app/* resulting css */
{
  -webkit-filter: contrast(250%) brightness(200%);
          filter: contrast(250%) brightness(200%);
  background: 
	linear-gradient(300deg, rgba(196, 128, 10, 0.36), rgba(0,0,0,0.9)), 
  radial-gradient(at var(--mouse-x) var(--mouse-y), rgba(255,255,255,0.5),  rgba(0,0,0,0.5)),
	url(/static/media/noise.b8980d0c.svg);
  height: 100vh;
}
  


.footer {
  display: flex; /* Use a flex container to align the divs horizontally */
  justify-content: center; /* Center horizontally */
  align-items: center;

}

.footer div, a {
  font-family: 'SV Basic Manual';
  color: white;
  text-align: center;
  margin-right: 10px; /* Add some spacing between the divs */
  margin-left: 10px; /* Add some spacing between the divs */
}
.img-hover-zoom--quick-zoom {
    /* Modify this according to your need */
    height: 300px;
    overflow: hidden;
    /* Removing this will break the effects */
    -webkit-clip-path: circle(30px at 30px 35px);
            clip-path: circle(30px at 30px 35px);

}

.img-hover-zoom--quick-zoom img {
    transform-origin: 0 0 0 0 ;
    transition: transform .25s, visibility .25s ease-in;
    height: 100px;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);

}

/* The Transformation */

.img-hover-zoom--quick-zoom:hover img {
    transform: scale(1.1);
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
}
@font-face {
  font-family: 'SV Basic Manual';
  src: url(/static/media/SvBasicManual.94f4609a.woff) format('woff');
  
  /* Add more src formats for better browser compatibility */
}
@font-face {
  font-family: 'SV Basic Manual Bold';
  src: url(/static/media/SvBasicManual-Bold.f89a601d.woff) format('woff');
  
  /* Add more src formats for better browser compatibility */
}
@font-face {
  font-family: 'Powerr-Bold';
  src: url(/static/media/Powerr-Bold.93e7984e.woff) format('woff');
  
  /* Add more src formats for better browser compatibility */
}

:root {
  --border-size: 0.125rem;
  --duration: 250ms;
  --ease: cubic-bezier(0.215, 0.61, 0.355, 1);
  /* --font-family: monospace; */
  --color-primary: white;
  --color-secondary: rgba(204, 143, 15, 0.7);
  /* --color-tertiary: #7557F1; */
  --shadow: rgba(0, 0, 0, 0.1);
  --space: 1rem;
}

  
.section-home span{
  color: white;
}
.section-home h1{
  color: white;
  font-size: 5em;
  
}
.section-home span:hover{
  color: rgba(204, 143, 15, 0.7);
  color: var(--color-secondary);
}
.section-home {
  font-family: 'Powerr Bold';
  text-align: center;
  
  padding: 20px;
  
}

.btn-div-home {
  margin: 0 auto;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
  width: 50%;
 
}

.btn-div-home a{
  font-family: 'SV Basic Manual Bold';
  border-style: none solid solid none;
  border-color: white;
  color: rgba(204, 143, 15, 0.7);
  color: var(--color-secondary);
  background-color: black;
  padding: 10px 20px 10px 20px;
  margin: 10px 10px 10px 10px;
  white-space: nowrap;
  box-shadow: rgba(0, 0, 0, 0.1) -2px -2px 4px 4px;
  box-shadow: var(--shadow) -2px -2px 4px 4px;
}


.btn-div-home a:hover {
  color: black;
  background-color: white;
  
}


/* Typewriter */
.section-home p{
  font-family: 'SV Basic Manual';
  border-right: solid 3px rgba(0,255,0,.75);
  white-space: nowrap;
  overflow: hidden;    
  font-size: 28px;
  color: rgba(255,255,255,.70);
  margin: 0 auto;
}

/* Animation */
.section-home p{
  animation: animated-text 3s steps(29,end) 1s 1 normal both,
             animated-cursor 600ms steps(29,end) infinite;
}

/* text animation */

@keyframes animated-text{
  from{width: 0;}
  to{width: 230px;}
}

/* cursor animations */

@keyframes animated-cursor{
  from{border-right-color: rgba(204, 143, 15, 0.7);border-right-color: var(--color-secondary);}
  to{border-right-color: transparent;}
}



.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 500ms;
}
@font-face {
  font-family: 'SV Basic Manual';
  src: url(/static/media/SvBasicManual.94f4609a.woff) format('woff');

  /* Add more src formats for better browser compatibility */
}

@font-face {
  font-family: 'SV Basic Manual Bold';
  src: url(/static/media/SvBasicManual-Bold.f89a601d.woff) format('woff');

  /* Add more src formats for better browser compatibility */
}

@font-face {
  font-family: 'Powerr-Bold';
  src: url(/static/media/Powerr-Bold.93e7984e.woff) format('woff');

  /* Add more src formats for better browser compatibility */
}

/* Projects.css */
.home-display {
  text-align: center;
}

h1 {
  font-family: 'SV Basic Manual Bold';
  color: white;
  font-size: 5em;

}

h3 {
  font-family: 'SV Basic Manual';
  color: rgba(204, 143, 15, 0.7);
}

.project {
  margin: 10px;
  padding: 10px;
  overflow: hidden;

  /* Slow down the transition for max-height */
}

.project h3 {
  color: #c27114;
  margin: 0;
}

.project p {
  color: white;
  margin: 0 100px 0 100px;
  font-family:'Courier New', Courier, monospace;
}

.arrow-button {
  color: #fff;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  padding: 5px 10px;

  transition: background-color 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.arrow-button:hover {
  background: none;
}

.arrow-button:focus {
  outline: none;
}
