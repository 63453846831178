@font-face {
  font-family: 'SV Basic Manual';
  src: url('../fonts/SvBasicManual.woff') format('woff');

  /* Add more src formats for better browser compatibility */
}

@font-face {
  font-family: 'SV Basic Manual Bold';
  src: url('../fonts/SvBasicManual-Bold.woff') format('woff');

  /* Add more src formats for better browser compatibility */
}

@font-face {
  font-family: 'Powerr-Bold';
  src: url('../fonts/Powerr-Bold.woff') format('woff');

  /* Add more src formats for better browser compatibility */
}

/* Projects.css */
.home-display {
  text-align: center;
}

h1 {
  font-family: 'SV Basic Manual Bold';
  color: white;
  font-size: 5em;

}

h3 {
  font-family: 'SV Basic Manual';
  color: rgba(204, 143, 15, 0.7);
}

.project {
  margin: 10px;
  padding: 10px;
  overflow: hidden;

  /* Slow down the transition for max-height */
}

.project h3 {
  color: #c27114;
  margin: 0;
}

.project p {
  color: white;
  margin: 0 100px 0 100px;
  font-family:'Courier New', Courier, monospace;
}

.arrow-button {
  color: #fff;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  padding: 5px 10px;

  transition: background-color 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.arrow-button:hover {
  background: none;
}

.arrow-button:focus {
  outline: none;
}