.img-hover-zoom--quick-zoom {
    /* Modify this according to your need */
    height: 300px;
    overflow: hidden;
    /* Removing this will break the effects */
    clip-path: circle(30px at 30px 35px);

}

.img-hover-zoom--quick-zoom img {
    transform-origin: 0 0 0 0 ;
    transition: transform .25s, visibility .25s ease-in;
    height: 100px;
    filter: grayscale(100%);

}

/* The Transformation */

.img-hover-zoom--quick-zoom:hover img {
    transform: scale(1.1);
    filter: grayscale(0);
}